import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import colors from '../../utils/colors';
import { Box, Flex } from '../Layout';
import feather from '../../utils/feather';
import media from '../../utils/media';
import { marginTop } from 'styled-system';

const footerStyle = css`
  overflow: hidden;
  padding: 0.3rem 0;
  background-color: ${colors.accent};
  color: rgba(51, 49, 50);
  text-align: center;

  & img {
    display: block;
    margin: 0;
  }
  & p {
    color: rgba(99, 100, 102);
    margin-top: 0px ;
    margin-bottom: 5px ;
  }
`;

const ulStyle = css`
  list-style-type: none;
  display: flex;
  margin: 0;
  margin-bottom: 1.45rem;
  text-transform: uppercase;
  padding: 0;
  & a {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.15s ease-in;
    transition: color 0.15s ease-in;

    &:hover {
      text-decoration: none;
      box-shadow: none;
      opacity: 1;
      transition: opacity 0.15s ease-in;
    }
  }
  & li {
    margin: 0 0 0 2rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0;
  text-align: left;
  height: 2rem;
  & p {
    margin: 0;
    margin-bottom: 0rem;
  }
`;

const socialList = css`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${media.large`
    width: 70px;
    margin-left: auto;
  `};
`;

const svgStyles = css`
  opacity: 0.5;
  transition: opacity 0.15s ease-in;
  transition: color 0.15s ease-in;

  &:hover {
    text-decoration: none;
    box-shadow: none;
    opacity: 1;
    transition: opacity 0.15s ease-in;
  }
`;

const Footer = ({ name, linkedin, email }) => (
  <div className="no-printme">
  <div className={footerStyle}>
    <Box width={[1, 1, 1, 2 / 3]} minWidth={[0,0,0, '1024px']} m="0 auto" p={[3, 3, 4, 1]} align="left" color="white">
      <Flex wrap={['wrap', 'wrap', 'nowrap']} justifyContent="space-between">
        <Box width={[1, 1, 1 / 3]}>
          <Wrapper>
            <div style={{marginTop: "-3px", fontSize: "13px", color: "#dddd"}}>
              <div style={{marginTop: "-4px"}}>{`© 2024 ${name} (ver. 1.0.18). All rights reserved.`}</div>
              <div style={{marginTop: "-2px"}}>{'Liner Analytics Pte. Ltd.'}</div>
            </div>
          </Wrapper>
        </Box>
        <Box width={[1, 1 , 1 / 3] } minWidth={[0,0, '400px']}>
          <Wrapper>
            <ul className={ulStyle}>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Service</Link>
              </li>
            </ul>
          </Wrapper>
        </Box>
        <Box width={[1, 1, 1 / 3]}>
          <Wrapper>
            <ul className={socialList}>
              <li>
                <a
                  title="Link to our LinkedIn page"
                  href={linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {feather('linkedin', ['30', '30'], svgStyles)}
                </a>
              </li>
              <li>
                <a title="Our E-Mail address" href={`mailto:${email}`}>
                  {feather('mail', ['30', '30'], svgStyles)}
                </a>
              </li>
            </ul>
          </Wrapper>
        </Box>
      </Flex>
    </Box>
  </div>
  </div>
);

Footer.propTypes = {
  name: PropTypes.string,
  linkedin: PropTypes.string,
  email: PropTypes.string
};

Footer.defaultProps = {
  name: 'Linerlytica',
  linkedin: 'https://www.linkedin.com/company/linerlytica/',
  email: 'editor@linerlytica.com'
};

export default Footer;
